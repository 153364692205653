<template>
	<div class="index-box">
		<div class="header-box">
			<div class="header-left">
				<span style="min-width: 100px;">项目概览</span>
				<!-- <el-select v-model="selectValue" filterable placeholder="请选择" @change="valueChange">
          <el-option v-for="item in tableNameList" :key="item" :label="item" :value="item">
          </el-option>
        </el-select> -->
			</div>
			<div class="header-right">
				<!-- <el-input v-model="searchValue" placeholder="搜索"></el-input>
        <div class="hdBtnBox" style="display: flex; margin-left: 10px;">
          <el-button type="primary" icon="el-icon-search">搜索</el-button>
          <el-button type="primary" icon="el-icon-upload2" @click="derive">导出</el-button>
          <el-button type="primary" @click="save">保存</el-button>
        </div> -->
			</div>
		</div>
		<div class="main-box">
			<div class="main-chart">
				<div class="chart">
					<div class="chart-title">
						<span>表单统计</span>
						<span class="current-num">当前表单数 365</span>
					</div>
					<div id="tableStatistic"></div>
				</div>
				<div class="chart">
					<div class="chart-title">
						<span>样表统计</span>
						<span class="current-num">当前样表数 365</span>

					</div>
					<div id="templateStatistic"></div>
				</div>
			</div>
			<!-- <div class="main-member"></div> -->
			<div class="main-right">
				<div class="item-header">
					<span>成员管理</span>
					<p>
						<!-- <span class="el-icon-search"></span> -->
						<!-- <span class="el-icon-sort"></span> -->
						<el-input v-model="pullUser" style="width:200px;" placeholder="通过帐号添加成员" v-if="action.pulluser||action.adduser"></el-input>
						<span class="el-icon-s-custom" style="color: #0093D0;margin-left:15px" @click="addUserToProject()" v-if="action.pulluser"></span>
					</p>
				</div>
				<ul class="item-main">
					<li v-for="item in userList" :key="item.userName">
						<i>
							<!-- <el-image :src="item.pictureUrl" class="headerImg" :onerror="errorImg"></el-image> -->
							<img :src="sureImg" alt="">
							<span>{{item.userName}}</span>
						</i>
						<span>{{item.nickName}}</span>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import Highcharts from 'highcharts/highstock'
import HighchartsMore from 'highcharts/highcharts-more'
HighchartsMore(Highcharts)
export default {
  name: 'Index',
  data () {
    return {
      pullUser: '',
      errorImg: 'this.src="' + require('../assets/header.png') + '"',
      sureImg: require('../assets/header.png'),
      jurisdiction: [{
        label: '管理员',
        id: 1
      }, {
        label: '普通成员',
        id: 2
      }],
      userList: [{
        imgUrl: require('../assets/header.png'),
        name: 'Mark',
        teml: '98756464@qq.com'
      }, {
        imgUrl: require('../assets/header.png'),
        name: 'Mark',
        teml: '98756464@qq.com'
      }]
    }
  },

  computed: {
    ...mapState('action', {
      action: 'action'
    })
  },
  mounted () {
    this.initChart('tableStatistic', 'column', '表单数(个)')
    this.initChart('templateStatistic', 'line', '样表数(个)')
    this.getUser()
  },
  methods: {
    initChart (id, type, text) {
      const chart = new Highcharts.Chart(
        document.getElementById(id), {
          chart: {
            type
          },
          credits: {
            enabled: false // 去掉水印
          },
          legend: {
            align: 'left',
            verticalAlign: 'top'
          },
          title: {
            text: ''
          },
          subtitle: {
            text: ''
          },
          colors: ['#0CB8C6', '#5A1CB8'],
          xAxis: {
            categories: [
              'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
              'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
            ],
            crosshair: true
          },
          yAxis: {
            min: 0,
            title: {
              text
            }
          },
          tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
								'<td style="padding:0"><b>{point.y:.1f}个</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
          },
          plotOptions: {
            column: {
              borderWidth: 0
            }
          },
          series: [{
            name: '本年',
            data: [49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4]
          }, {
            name: '同比',
            data: [83.6, 78.8, 98.5, 93.4, 106.0, 84.5, 105.0, 104.3, 91.2, 83.5, 106.6, 92.3]
          }]
        })
    },
    ...mapActions('menu', {
      getMenu: 'getMenu',
      clickMenuItem: 'clickMenuItem'
    }),
    ...mapMutations('menu', {
      initTabs: 'initTabs'
    }),
		    getUser () {
		    	this.$axios.get('/api/User/GetUsers').then(res => {
		    		console.log('000000000000', res)
        this.userList = res
      })
		    },
    addUserToProject () {
      if (!this.pullUser) { // 未输入用户名
        this.$message({
          type: 'warning',
          message: '请输入要邀请的用户!'
        })
      } else {
        this.$axios.get(`/api/Project/ProjectUser?userName=${this.pullUser}`).then(res => {
          this.$message({
            type: 'success',
            message: '邀请成功'
          })
          this.userList.push(res)
        })
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    var _this = this
    next(vm => {
      if ((from.name == 'project' || from.name == 'space') && to.name == 'index') {
        var newObject = {
          hasChilder: false,
          includeCom: 'Index',
          index: 'index',
          label: '项目概览',
          name: '项目概览',
          icon: 'icon-xiangmugailan'
        }
        vm.initTabs([newObject])
      }
    })
  }
}
</script>

<style scoped lang="less">
	.index-box {
		// height: 100%;
		height: calc(100vh - 100px);
		padding: 15px 25px;
		box-sizing: border-box;
		overflow: hidden;
	}

	.header-box {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 69px;
		box-shadow: 0px 2px 15px 0px rgba(187, 187, 187, 0.5);
		border-radius: 8px;
		margin-bottom: 10px;
		background-color: #ffffff;
	}

	.header-left {
		display: flex;
		align-items: center;
		font-weight: bold;
		margin-left: 30px;
	}

	.header-right {
		display: flex;
		height: 40px;
		margin-right: 30px;
	}

	.main-box {
		// background-color: #000000;
		margin-top: 15px;
		height: calc(100vh - 215px);
		display: flex;
	}

	.main-chart {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex: 10;
		margin-right: 25px;
		// padding-bottom: 10px;
		/* height: calc(100vh - 90px); */
		/* background-color: #000000; */
	}

	.chart {
		width: 100%;
		/* height: 40%; */
		height: 48%;
		background-color: #fff;
		box-shadow: 0px 2px 15px 0px rgba(187, 187, 187, 0.5);
		border-radius: 8px;
		overflow: hidden;
	}

	#tableStatistic {
		width: 100%;
		/* height: 280px; */
		height: calc(100% - 20px);
	}

	#templateStatistic {
		width: 100%;
		height: calc(100% - 20px);
	}

	.chart-title {
		height: 50px;
		line-height: 50px;
		padding-left: 15px;
		background-color: #f8f8f9;
		border-radius: 8px 8px 0 0;
		text-align: left;
		font-size: 18px;
		font-weight: 600;
	}

	.current-num {
		margin-left: 15px;
		color: #4c4c4c;
		font-size: 14px;
		font-weight: 400;
	}

	.main-member {
		flex: 4;
		background-color: #fff;
	}

	.main-right {
		width: 445px;
		height: calc(100% - 0px);
		// background-color: #000000;
		margin-left: 20px;
		box-shadow: 0px 2px 15px 0px rgba(187, 187, 187, 0.5);
		border-radius: 8px;
		overflow: hidden;

		.item-header {
			display: flex;
			height: 47px;
			background-color: #F8F8F9;
			justify-content: space-between;
			align-items: center;
			box-sizing: border-box;
			font-weight: bold;
			padding: 0 30px;

			p {
				span {
					cursor: pointer;
					margin: 0 5px;
				}
			}
		}

		.item-main {
			padding: 0 20px;
			background-color: #FFFFFF;
			height: calc(100vh - 138px);
			overflow-y: auto;

			li {
				display: flex;
				align-items: center;
				justify-content: space-between;
				height: 70px;
				border-bottom: 1px solid #e1e1e1;
				padding: 0 10px;
				font-weight: bold;

				i {
					display: flex;
					align-items: center;

					img {
						height: 50px;
						width: 50px;
					}
					.headerImg {
						height: 50px;
						width: 50px;
					}

					span {
						margin-left: 15px;
					}
				}

			}
		}
	}
</style>
